.content{
    font-family: Open Sans;
    // justify-content: center;
    max-width: 450px;
}

p, span {
    font-size: 18px;
}

h2 {
    font-size: 20px;
}

.route-container{
    height: 0px;
    width: 0px;
  
    svg.route{
      position: relative;
      overflow: visible;
    
      left: -270px;
  
      path{
        stroke-width: 10px;
        position: absolute;
      }
    }
}
