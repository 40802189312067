.station-container.base{
  position: relative;

  .station.base{
    position: absolute;
    top: 17.5px;
    left: -140px;
  
    background: #010203;
    border-radius: 50%;
  
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0px;
  }

  .inner-stat.base{ 
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    background: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}

.station-container.t2{
  position: relative;

  .station.t2{
    background: #010203;
    border-radius: 50%;
  
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0px;
  }
  @media (max-width: 1275px){
    .station.t2{
      position: absolute;
      left: -64px;
      top: -42px;
    }
  }

  @media (min-width: 1276px){
    .station.t2{
      position: absolute;
      left: -64px;
      top: -42px;
    }
  }

  @media (min-width: 1290px){
    .station.t2{
      position: absolute;
      left: -74px;
      top: -42px;
    }
  }

  @media (min-width: 1325px){
    .station.t2{
      position: absolute;
      left: -84px;
      top: -42px;
    }
  }

  @media (min-width: 1350px){
    .station.t2{
      position: absolute;
      left: -88px;
      top: -42px;
    }
  }

  @media (min-width: 1400px){
    .station.t2{
      position: absolute;
      left: -89px;
      top: -42px;
    }
  }


  @media (min-width: 1450px){
    .station.t2{
      position: absolute;
      left: -91px;
      top: -42px;
    }
  }

  @media (min-width: 1500px){
    .station.t2{
      position: absolute;
      left: -92px;
      top: -42px;
    }
  }

  .inner-stat.t2{ 
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    background: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}
