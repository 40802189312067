.train{
  height: 0px;
  width: 0px;
  position: relative;
  
  .train-car {
    position: absolute;
    top: -15px;
    left: -295px;
    height: 30px;
    width: 50px;
    z-index: 1;
    overflow: clip;
    opacity: 0;
  }
}