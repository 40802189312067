body{
  overflow-x: clip;
  max-width: 100vw;

  a {
    color: #010203;
  }

  p, span, h3, h4, strong {
    opacity: 0;
  }

  #site{
    padding-top: 50px;
    padding-bottom: 150px;
    overflow-y: clip;
  }

  @media (max-width: 475px){
    .rail{
      display: none;
    }

    .content{
      max-width: 100%;
      overflow-x: clip;
    }
  }
}
