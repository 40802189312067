div#about-me{
  margin: 25px 0px 35px 0;
}

span#hey {
  font-weight: 800;
}

span#name {
  font-family: Oxygen;
  font-size: 37px;
}
